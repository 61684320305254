<template>
  <b-row>
    <!-- use component -->
    <index-form-modal
      :ref="`indexFormModal${_role}`"
      @reload="$refs[`tableUserInfo${_role}`].reloading()"
      :role="_role"
    />
    <indexClickViewUserInfoModal :ref="`indexClickViewUserInfoModal${_role}`" />
    <!-- end use component -->
    <b-col sm="12">
      <TableDataServerSide
        :ref="`tableUserInfo${_role}`"
        :title="`ข้อมูล ${_title}`"
        :url="`/api/admin/user-data-table?role=${_role}`"
        @clickNew="$refs[`indexFormModal${_role}`].show()"
        @clickView="clickView($event)"
        @clickEdit="$refs[`indexFormModal${_role}`].show({ ...$event })"
        @clickDelete="$refs.tableUserInfo.clickDelete('/api/admin/users', $event)"
        :columns="cColumn"
        :newAble="cCanNewAble"
        viewAble
        editAble
        deleteAble
        :btnText="`เพิ่มข้อมูล ${_title}`"
      >
        <template #header-table>
          <div v-if="_role === 'User'" class="d-flex mb-2">
            <b-input
              v-model="paymentUUID"
              type="text"
              class="w-50 mr-50"
              placeholder="ระบุ UUID ผู้ใช้ทั่วไป เพื่อสร้าง Link Payment"
            />
            <button-widget text="ตกลง" variant="primary" :loading="btnPaymentLoading" @click="goToUserDetailsScreen" />
          </div>
        </template>

        <template #country_code="{row}">
          <div class="d-flex align-items-center justify-content-center">
            <b-img
              v-if="row.country_flags_url"
              :src="row.country_flags_url"
              :alt="`${row.country_code || '-'}`"
              class="mr-50"
              height="30"
            />
            <span>{{ row.country_code || '-' }}</span>
          </div>
        </template>
      </TableDataServerSide>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MutewallIndex',
  props: {
    role: null,
  },
  components: {
    indexFormModal: () => import('./IndexFormModal.vue'),
    indexClickViewUserInfoModal: () => import('./IndexClickViewUserInfoModal.vue'),
  },
  data() {
    return {
      results: [],
      columns: [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'email',
        },
        {
          label: 'เครดิตคงเหลือ',
          field: 'credit_sandbox',
          type: 'number',
        },
        {
          label: 'ยอดขาย',
          field: 'credit_balance',
          type: 'number',
        },
        {
          label: 'สมัครจาก',
          field: 'provider',
        },
        {
          label: '',
          field: 'action',
        },
      ],
      paymentUUID: '',
      btnPaymentLoading: false,
    }
  },
  computed: {
    _role() {
      return this.role?.role
    },
    _title() {
      return this.role?.title
    },
    cCanNewAble() {
      if (this.role?.role === 'User' || this.role?.role === 'Partner') return false
      return true
    },
    cColumn() {
      const commonColumn = [
        {
          label: 'อีเมล / เบอร์โทรศัพท์',
          field: 'email',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'ชื่อ สกุล',
          field: 'fullName',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'เครดิตคงเหลือ',
          field: 'credit_sandbox',
          type: 'number',
          filterable: true,
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
      ]

      let newColumn = []

      if (this.role.role === 'User') {
        newColumn = [
          {
            label: 'ประเทศ',
            field: 'country_code',
            type: 'custom',
            thClass: 'text-center',
          },
          ...commonColumn,
          {
            label: 'สมัครจาก',
            field: 'provider',
          },
        ]
      } else {
        newColumn = [
          ...commonColumn,
          {
            label: 'ยอดขาย',
            field: 'credit_balance',
            type: 'number',
          },
        ]
      }
      return [
        ...newColumn,
        {
          label: '',
          field: 'action',
          sortable: false,
        },
      ]
    },
  },

  mounted() {},

  methods: {
    clickView(data) {
      this.$router.push({ name: 'userManagement-user-info', params: { userId: data.id } })
    },
    async goToUserDetailsScreen() {
      if (!this.paymentUUID) {
        this.gDisplayToast('โปรดระบุ UUID', '', 'danger')
        return
      }
      this.btnPaymentLoading = true
      const resp = await this.api
        .get(`api/customers/dashboard/customer-information/${this.paymentUUID}`)
        .catch(() => null)

      this.btnPaymentLoading = false

      if (resp && resp?.customer_information?.id) {
        // const url = `https://payment.ongphra.com?id=${this.paymentUUID}`
        // window.open(url, '_blank')
        this.$router.push({ name: 'userManagement-user-info', params: { userId: this.paymentUUID } })
        return
      }

      this.gDisplayToast('โปรดตรวจสอบ UUID ใหม่อีกครั้ง', 'เนื่องจากไม่พบผู้ใช้ในระบบที่ตรงกับ UUID นี้', 'danger')
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
